<template>
    <div class="home" 
      style=" position: relative;width: 100%;display: flex;flex-direction: column; justify-content: center;">
      <img style="width: 100%;" src="../assets/mars/首屏背景3.png" alt="">
      <div id="top" class="topv">
        <small-topview v-if="showSmall && !topHidden"> </small-topview>
        <top-view ref="toptitle" v-if="!showSmall && !topHidden" @changeLang="changeLang" @selectTable="selectTable"></top-view>
        <img  v-if="lang == 'en'" class="banneri" src="../assets/mars/banner-e.png" alt="">
        <img v-else class="banneri" src="../assets/mars/banner.png" alt="">
      </div>
  
      <div class="topjs">
        <img class="topjs-mouse" src="../assets/mars/鼠标@3x.png" alt="">
        <div class="topjs-items">
          <img v-if="lang == 'en'" style="width: 98%;" src="../assets/mars/编组 9.png" alt="">
          <img v-else style="width: 98%;" src="../assets/mars/编组 9-e.png" alt="">
        </div>
      </div>
  
      <div id="video" ref="video" class="videov">
        <div class="videov-title">{{ $t("titles.five") }}</div>
        <div class="videov-video">
          <wordcloud :key="lang + screenWidth"></wordcloud>
        </div>
      </div>
  
      <div class="bottomView">
        <img v-if="lang== 'zh'" class="bottomView-img" src="../assets/mars/bottomimg.png" alt="">
        <img v-if="lang== 'en'" class="bottomView-img" src="../assets/mars/bottomimg-e.png" alt="">
  
        <!-- <img v-if="lang == 'en'" class="text-en" src="../assets/mars/文字英.png" alt="">
        <img v-else class="text-zh" src="../assets/mars/文字中.png" alt=""> -->
  
        <div class="bottomview-code" id="code">
          <div class="bottomview-code-item">
            <img class="code-item" src="../assets/mars/更多资讯.png" alt="">
            <div class="code-text">{{$t('titles.six')}}</div>
          </div>
          <div class="bottomview-code-item" style="margin-left: 40px ;">
            <img class="code-item" src="../assets/mars/飞企微二维码.jpg" alt="">
            <div class="code-text">{{$t('titles.seven')}}</div>
          </div>
        </div>
      </div>
  
      <div class="bline">
        <div class="bline-c">北京华声志海文化传播有限公司@2022</div>
        <a class="bline-c" style="margin-left: 16px;" href="https://beian.miit.gov.cn/">京ICP备2022016391号-3</a>
      </div>
  
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  
  // import * as API_URL from '../api/url'
  import topView from './components/homeTop.vue'
  import smallTopview from './components/smallScreenTopview.vue'
  import wordcloud from './components/wordcloud.vue'
  import debounce from '@/libs/debounce'
  export default {
    name: 'Home',
    components: {
      topView,
      smallTopview,
      wordcloud
    },
    metaInfo: {
      title:'火星1号基地3',
      meta:[{
        name:'火星1号基地关键字3',
        content:'火星1号基地描述3',
      }]
    },
    data() {
      return {
        showSmall: false,
        screenWidth: 0,
        lang: 'zh',
        topHidden:false,
      }
    },
    methods: {
      messageClick() {
        this.$message.error('点击');
      },
  
      selectTable(e) {
        var anchor;
        if (e == 0) {
          anchor = document.getElementById('top')
          anchor.scrollIntoView({ behavior: "smooth" });
        } else if (e == 1) {
          anchor = document.getElementById('video')
          anchor.scrollIntoView({ behavior: "smooth" });
        } else if (e == 2) {
          anchor = document.getElementById('code')
          anchor.scrollIntoView({ behavior: "smooth" });
        }
        this.selectIndex = e
       
  
      },
  
      changeLang() {
        let isZh = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh';
        if (isZh === 'zh') {
          localStorage.setItem('lang', 'en'); // en表示英文，zh表示中文，可根据自己喜好设定，尽量通俗易懂
          this.$i18n.locale = 'en'
          this.lang = 'en'
        } else {
          localStorage.setItem('lang', 'zh');
          this.$i18n.locale = 'zh'
          this.lang = 'zh'
        }
  
        // location.replace(location); //刷新网页
        // this.$router.replace('/');
        // this.$router.go(0);
        // window.location.reload();
      },
  
  
      viewScroll(){
        let current =
          document.documentElement.scrollTop || document.body.scrollTop;
        let vh = window.innerHeight;
        console.log(current,vh)
        if(current>120){
          if(!this.topHidden){
            this.topHidden = true
          }
        }
  
        if(current<120){
          if(this.topHidden){
            this.topHidden = false
            this.$refs.toptitle.selectIndex = 0
          }
        }
      },
  
      resetScrennwidth: debounce(function () {
        this.screenWidth = document.body.clientWidth
      }, 300),
  
    },
  
    mounted() {
      //获取屏幕尺寸
      this.screenWidth = document.body.clientWidth
      window.onresize = () => {
        console.log('高度');
        //屏幕尺寸变化
        return (() => {
          this.resetScrennwidth()
        })()
      }

      this.$refs.toptitle.selectIndex = 3
      
      window.addEventListener('scroll', this.viewScroll, true)
    },
  
    watch: {
      screenWidth: function (n, o) {
        console.log(o)
        if (n <= 950) {
          console.log('屏幕宽度小于400了')
          if (!this.showSmall) {
            this.showSmall = true
          }
  
        } else {
          if (this.showSmall) {
            this.showSmall = false
          }
          console.log('屏幕宽度没有小于1200')
        }
      }
    }
  
  }
  
  
    // mounted() {
    // API_URL.delAfterSaleReason(1).then((res) => {
    //             if (res.success) {
    //                 console.log(res)
    //             }
    //         });
  
  
    // }
  </script>
  
  <style lang="scss" scoped>
  // @import url('./css/home.scss');
  
  .home {
    background-color: black;
    box-sizing: border-box;
    overflow-x: hidden;
    height: fit-content;
    min-width: 1200px;
  }
  
  .topv {
    // position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    box-sizing: border-box;
  }
  
  .banneri {
    position: absolute;
    width: 90%;
    top: calc(100vw * (200 / 1920));
    right: 5%;
  }
  
  .banneri-leftview {
    position: absolute;
    top: 14%;
    left: 10%;
    width: 38.8%;
  }
  
  .topjs {
    position: relative;
    margin-top: -30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
  
  .topjs-mouse {
    width: calc(100vw * (38 / 1920));
  }
  
  .topjs-items {
    margin-top: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  
  }
  
  .topjs-items-item {
    width: 375px;
    height: 364px;
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
  
  }
  
  .topjs-items-item-centent {
    width: 90%;
    height: 364px;
    margin-top: -15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border: 1px solid #fff;
  
  
  
  }
  
  .topjs-items-item-topimg {
    width: 100%;
  }
  
  .topjs-items-item-message {
    margin-top: 20px;
    font-size: calc(100vw * (16 / 1920));
    color: #fff;
  }
  
  .topjs-items-item-line {
    margin-top: 13px;
    width: 80%;
    height: 1.5px;
    opacity: 0.5;
    background-color: #fff;
    border-radius: 1px;
  }
  
  .topjs-items-item-textview {
    display: flex;
    align-items: center;
  
  }
  
  .topjs-items-item-textview-big {
    font-size: calc(100vw * (24 / 1920));
    color: #fff;
    font-weight: bold;
  }
  
  .topjs-items-item-textview-small {
    font-size: calc(100vw * (16 / 1920));
    font-weight: 400;
    color: #fff;
    margin-left: 10px;
    margin-left: 5px;
  }
  
  .videov {
    position: relative;
    width: 100%;
    height: 700px;
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  
    .videov-title {
      margin-top: 90px;
      width: 100%;
      text-align: center;
      height: 36px;
      font-size: calc(100vw * (24 / 1920));
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
    }
  
    .videov-video {
      margin-top: 2%;
      width: 100%;
      height: 500px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  .bottomView {
    width: 100%;
    position: relative;
    // margin-top: -20%;
    z-index: 8;
    box-sizing: border-box;
  
  
    .bottomView-rightView {
      display: flex;
      position: absolute;
      right: 20px;
      top: calc(100vw * (400 / 1920));
      width: 200px;
      height: 300px;
      background-color: #ffffff;
    }
  
  
  }
  
  .bottomView-img {
    width: 100%;
  }
  
  .bottomview-code {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: calc(100vw * (50 / 1920));
    z-index: 9;
  
    .bottomView-code-item {
      width: calc(100vw * (140 / 1920));
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .bottomView-code-item-text {
        margin-top: 10px;
        color: #fff;
        font-size: calc(100vw * (16 / 1920));
      }
    }
  }
  
  .code-item {
    width: calc(100vw * (160 / 1920));
    height: calc(100vw * (160 / 1920));
    border: 5px solid #fff;
  }
  
  .code-text {
    color: #fff;
    font-size: calc(100vw * (16 / 1920));
    margin-top: calc(100vw * (10 / 1920));
  }
  
  .bline {
    height: 93px;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  
  }
  
  .bline-c {
    color: #fff;
    height: 30px;
    line-height: 30px;
    font-size: calc(100vw * (16 / 1920));
  }
  
  .text-zh {
    position: absolute;
    top: calc(100vw * (370 / 1920));
    right: calc(100vw * (200 / 1920));
    width: calc(100vw * (370 / 1920));
  }
  
  
  .text-en {
    position: absolute;
    top: calc(100vw * (370 / 1920));
    right: calc(100vw * (100 / 1920));
    width: calc(100vw * (473 / 1920));
  }
  </style>
  